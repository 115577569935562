<template>
  <r-e-dialog
      title="抬头"
      class="layer-contract-detail"
      :visible.sync="addDialogVisible"
      show-footer
      top="10vh"
      width="800px"
      @click-submit="clickSubmit"
      @click-cancel="clickCancel"
      @close="clickCancel"
  >
    <div class="invoice_top">
      <span>抬头类型：</span>
      <el-radio v-model="type" label="1">个人</el-radio>
      <el-radio v-model="type" label="2">单位</el-radio>
    </div>
<!--表单-->
    <el-form :rules="rules" ref="form" :model="formdata" label-width="80px" size="mini">
      <el-form-item label="发票抬头" prop="invoiceRise">
          <el-input  v-model="formdata.invoiceRise"></el-input>
      </el-form-item>
      <el-form-item label="税号" prop="taxNumber" v-if="type === '2'">
        <el-input  v-model="formdata.taxNumber"></el-input>
      </el-form-item>
      <el-form-item label="开户银行"  v-if="type === '2'">
        <el-input  v-model="formdata.depositBank"></el-input>
      </el-form-item>
      <el-form-item label="银行账号"  v-if="type === '2'">
        <el-input  v-model="formdata.bankAccount"></el-input>
      </el-form-item>
      <el-form-item label="企业地址"  v-if="type === '2'">
        <el-input  v-model="formdata.enterpriseAddress"></el-input>
      </el-form-item>
      <el-form-item label="企业电话"  v-if="type === '2'">
        <el-input  v-model="formdata.enterprisePhone"></el-input>
      </el-form-item>
      <el-form-item label="委托付款函" prop="samplePhotos"  v-if="type === '2'">
          <upload-pictureCard :uuidList="formdata.fileList" :limit="9" @on-success="handleSuccess"
                              @on-remove="handleRemove"/>
      </el-form-item>
    </el-form>

  </r-e-dialog>
</template>

<script>
import uploadPictureCard from "@/components/Upload/upload-picture-card.vue";
import {risedialogapi,risedialogapixiangqing} from "@/api/tenant-management";
import {extractUrlParameter} from "@storage/cookies";
export default {
  name: "dialog-add-invoice",
  components: {uploadPictureCard},
  data() {
    return {
      uuid:'',
      addDialogVisible: false,
      type: '1',
      formdata:{
        invoiceRise:'',//发票抬头
        taxNumber:'',//税号
        depositBank:'',//开户银行bankAccount
        bankAccount:'',//银行账号
        enterpriseAddress:'',//企业地址
        enterprisePhone:'',//企业电话
        fileList:[],
        samplePhotos:[],
        paymentAccountUrl:''
      },
      rules: {
        invoiceRise: [
          { required: true, message: '请输入发票抬头', trigger: 'blur' },
        ],
        taxNumber: [
          { required: true, message: '请输入税号', trigger: 'blur' },
        ],
        samplePhotos: [
          { required: true, message: '请添加委托付款函', trigger: 'blur' },
        ],
      }
    }
  },
  methods:{
    // 抬头回显，如果是设置过的，就让他回显
    openinvoiceclick(row){
      console.log(row)
      this.uuid = row.uuid
      risedialogapixiangqing(this.uuid).then(res => {
        let {info} = res
        console.log(info)
        if(info.invoiceRise){
          if(info.type === 1)this.type = '1'
          if(info.type === 2)this.type = '2'

          // this.formdata = info
          // 2024-4-26-陈守亮-小程序web照片区分
          let arr = info.paymentAccountUrl.split(',')
          arr =   arr.map(item => {
            return  item =extractUrlParameter(item)
           })
          console.log(arr)
          this.formdata = {
            invoiceRise:info.invoiceRise,//发票抬头
            taxNumber:info.taxNumber,//税号
            depositBank:info.depositBank,//开户银行
            bankAccount:info.bankAccount,//银行账号
            enterpriseAddress:info.enterpriseAddress,//企业地址
            enterprisePhone:info.enterprisePhone,//企业电话
            samplePhotos:info.paymentAccountUrl,
            fileList:arr
          }
          console.log('详情',this.formdata)
        }else {
          console.log('不是详情')
          this.formdata = {
            invoiceRise:'',//发票抬头
            taxNumber:'',//税号
            depositBank:'',//开户银行bankAccount
            bankAccount:'',//银行账号
            enterpriseAddress:'',//企业地址
            enterprisePhone:'',//企业电话
            fileList:[],
            samplePhotos:[],
            paymentAccountUrl:''
          }
        }
      })
      this.addDialogVisible = true
    },
    // 弹框确认
    clickSubmit(){
      console.log(this.formdata)
      this.$refs.form.validate((valid) => {
        if (valid) {
          let {invoiceRise,taxNumber,depositBank,bankAccount,enterpriseAddress,enterprisePhone,fileList,samplePhotos} = this.formdata
          console.log(this.formdata)
          risedialogapi(this.uuid,{invoiceRise,taxNumber,depositBank,bankAccount,enterpriseAddress,enterprisePhone,type:this.type,paymentAccountUrl:fileList.join()}).then(res => {
            console.log(res)
            this.$message({
              type: 'success',
              message: '抬头成功!'
            });
          })

          this.addDialogVisible = false

        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 关闭弹框
    clickCancel(){
      this.addDialogVisible = false
      // this.formdata = {}

    },
    handleSuccess(response) {
      console.log('我是添加',response.file.response.returnObject.info.uuid,response)
      const uuid = response.file.response.returnObject.info.uuid;
      this.formdata.fileList.push(uuid)
      console.log(this.formdata.fileList)
      this.formdata.samplePhotos =  this.formdata.fileList.join();
      // //2022/03/22 王江毅 图片上传后 将保存图片的字段拆分(拆分后判断长度等不等于0)后过滤(过滤空字符串，如果以空字符串进行拆分会返回长度为1的数组,且该值为空字符串)
      // const isTrue = this.formdata.samplePhotos.split(",").filter(item => item !== '').length !== 0;
      //
      // this.formdata.samplePhotos += isTrue ? ("," + uuid) : uuid;
    },
    handleRemove({fileList}) {
      console.log('我是删除',fileList)
      let arr = []
      fileList.forEach(item => {
        if(item.name){
          arr.push(item.name)
        }else {
          arr.push(item.response.returnObject.info.uuid)
        }
      })
      this.formdata.fileList = arr
      this.formdata.samplePhotos = arr.join()
      // const fileListName = fileList.map(v => v.name.split(".")[0]);
      // console.log(fileListName)
      // this.formdata.samplePhotos = fileListName.join(",");
      // this.formdata.fileList = this.formdata.samplePhotos.split(",").filter(item => item !== '');

    },
  }
}
</script>

<style scoped lang="scss">
.invoice_top{
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}
</style>